import {Component, OnDestroy, OnInit} from '@angular/core';
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {CredType, CredTypeMap} from "../shared/credentials";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {RegistrationService} from "../service/registration.service";
import {DisplayService} from "../service/display.service";

export enum RegistrationState {
  SELECT,
  NAME
}
@Component({
  selector: 'app-register-credentials',
  templateUrl: './register-credentials.component.html'
})
export class RegisterCredentialsComponent implements OnInit {

  errorDiv: boolean;
  errorMessage: string;
  regCredMap: { [id: string] : CredType } = {}
  showError: boolean;
  selectedCredType: string;

  formGroup: FormGroup;
  credValueControl: FormControl = new FormControl();
  vipCredControl: FormControl = new FormControl();

  regState: RegistrationState = RegistrationState.SELECT;
  State = RegistrationState;
  protected readonly infoIcon = faCircleInfo;

  pageSubs: Subscription[] = new Array<Subscription>();
  ready: boolean = true;

  constructor(private registrationService: RegistrationService,
              private displayService: DisplayService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      credValue: this.credValueControl,
      vipCredId: this.vipCredControl
    });
    this.setRegCredMap();
  }

  setRegCredMap() {
    Object.entries(CredTypeMap).forEach(([key, cred]) => {
      if (!cred?.disableReg) {
        this.regCredMap[key] = cred;
      }
    });
  }

  onRegister() {
    this.ready = false;
    this.errorDiv = false;
    this.errorMessage = "";
    this.registrationService.registerCredential(this.selectedCredType, this.credValueControl.value, this.vipCredControl.value).subscribe({
      next: (data) => {
        this.router.navigate([this.selectedCredType], {
          relativeTo: this.route,
          state: {data}
        });
      },
      error: (err) => {
        this.errorMessage = this.displayService.errorMsg;
        this.errorDiv = true;
        this.ready = true;
      }
    });
  }

  onBack() {
    if (this.errorDiv) {
      this.router.navigate(["/self-service"])
    } else if (this.regState === RegistrationState.NAME) {
      this.formGroup.reset();
      this.regState = RegistrationState.SELECT;
    } else {
      this.router.navigate(["/self-service"])
    }
  }

  private setFormRequirements() {
    if (this.selectedCredType === 'email') {
      this.credValueControl.setValidators([Validators.required, Validators.email]);
    } else if (this.selectedCredType === 'sms') {
      this.credValueControl.setValidators([Validators.required, Validators.minLength(11)]);
    } else {
      this.credValueControl.setValidators([Validators.required])
    }

    if (this.selectedCredType === "totp_push") {
      this.vipCredControl.setValidators([Validators.required])
    } else {
      this.vipCredControl.clearValidators();
    }
  }

  onSubmitType() {
    this.setFormRequirements();
    this.regState = RegistrationState.NAME;
    this.displayService.focusNthField();
  }
}
