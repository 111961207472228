import { Injectable } from '@angular/core';
import {GlobalService} from "./global.service";
import {Router} from "@angular/router";
import {DisplayService} from "./display.service";
import {HttpClient} from "@angular/common/http";
import * as uuid from "uuid";

@Injectable({
  providedIn: 'root'
})
export class ResetService {


  queryParams: URLSearchParams;

  constructor(private globalService: GlobalService,
              private http: HttpClient,
              private displayService: DisplayService,
              private router: Router
  ) {
    this.queryParams = new URLSearchParams();
  }

  /**
   * Method to dissociate an authenticated username with the current flow.
   */
  resetUser() {
    // Do not allow chain auth users to reset username (i.e. disable user reset on id propagation)
    if (this.globalService.allowUserReset && sessionStorage.getItem("x-flow") === "oauth2" && this.queryParams.get("x-flow-state")) {
      // Dissociates current username with flow state for oauth2 flow, providing new x-flow-state
      let postData = {
        'resetFlow': true,
        'subject': this.displayService.userName
      };

      return this.http.post(this.displayService.serviceUrl + 'auth/v1/authenticate', postData);
    }
  }

  resetAuthFlow() {
    if (sessionStorage.getItem("x-flow") === "oauth2") {
      this.router.navigate(['oauth2'], {queryParams: { restart: true }});
    } else {
      this.router.navigate(['direct'], {queryParams: { restart: true }});
    }
  }

  setQueryParams() {
    const queryString = window.location.search;
    this.queryParams = new URLSearchParams(queryString);
    this.displayService.flowState = this.queryParams.get("x-flow-state");
    this.displayService.clientTransactionId = this.queryParams.get("X-CLIENT-TRANSACTION-ID");
    if (!this.displayService.clientTransactionId) {
      this.displayService.clientTransactionId = this.getClientTxId();
    }
  }

  getClientTxId() {
    // Propagate existing ID if present in query params
    let ctxid = this.queryParams.get("X-CLIENT-TRANSACTION-ID");
    if (ctxid && ctxid !== "") {
      return ctxid;
    } else {
      return uuid.v4();
    }
  }

  clearQueryParams() {
    this.queryParams = new URLSearchParams();
  }

}
