import {Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DisplayService } from '../service/display.service';
import { FactorselectionService } from '../service/factorselection.service';
import { LdapService } from '../service/ldap.service';
import {Subscription} from "rxjs";
import {PlatformLocation} from "@angular/common";

const CHANGE_PWD_ERR_CODE = 1003033;
const CHANGE_PWD_HTTP_CODE = 428;

@Component({
  selector: 'app-ldap',
  templateUrl: './ldap.component.html'
})
export class LdapComponent implements OnInit, OnDestroy {
  passwordEnabled = false;
  errorMsg: String;
  errorDiv: boolean = false;
  userName = null;
  passwordForm: UntypedFormGroup;
  factorSelection: boolean = false;
  inlineMessage: string;
  ready: boolean = true;
  pageSubs: Subscription[] = new Array<Subscription>();

  constructor(private factorselectionService: FactorselectionService,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private ldapService: LdapService,
              private displayService: DisplayService) {
    this.displayService.disableBrowserBackButton();
  }

  ngOnInit() {
    if (this.displayService.userName == null) {
      this.router.navigate(["../"]);
    }
    this.inlineMessage = (this.displayService.inlineMessage || "").replace(/FIDO/g, "biometrics").replace(/SECURITYKEY/g, 'security key');
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(1)]]
    });
    this.passwordEnabled = this.displayService.passwordEnabled;
    this.factorSelection = this.displayService.factorSelection;
    this.displayService.focusNthField();
  }

  onPasswordSubmit() {
    this.errorDiv = false;
    this.ready = false;

    this.pageSubs = [this.ldapService.onPasswordLogin(this.passwordForm.value.password)
      .subscribe({
        next: (data) => {
          this.displayService.routeActions(data);
        },
        error: (err) => {
          if (this.checkChangePasswordError(err)) {
            this.displayService.routeActions(err.error);
          } else {
            this.errorDiv = this.displayService.errorDiv;
            this.errorMsg = this.displayService.errorMsg;
            this.ready = true;
          }
        }
      })
    ];
  }

  /**
   * Check if the error matches Broadcom's change password response and error code.
   *
   * Broadcom will throw error even when password succeeds, if the password must change. The change password action
   * will be invoked after the user completes all factors for the policy.
   *
   * Official documentation: https://techdocs.broadcom.com/us/en/symantec-security-software/identity-security/vip-authentication-hub/2-2/Using/Authentication-services/factor-services/Password-Authentication.html
   * @param err
   */
  checkChangePasswordError(err: any) {
    return (err?.status == CHANGE_PWD_HTTP_CODE && err?.error?.data?.errorCode == CHANGE_PWD_ERR_CODE);
  }


  factorSelect() {
    this.errorDiv = false;
    this.ready = false;
    this.pageSubs.push(
      this.factorselectionService.chooseAnother()
    );
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub) => {
      sub?.unsubscribe();
    });
  }
}
