import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {DisplayService} from "../service/display.service";
import { Clipboard } from '@angular/cdk/clipboard';
import {timer} from "rxjs";

@Component({
  selector: 'app-troubleshoot',
  templateUrl: './troubleshoot.component.html'
})
export class TroubleshootComponent implements OnInit {

  btnClipboardDisabled = false;
  userName;
  clientTxId;
  lastTransaction;

  constructor(public dialogRef: MatDialogRef<TroubleshootComponent>,
              private clipboard: Clipboard,
              private displayService: DisplayService) { }

  ngOnInit() {
    this.userName = this.displayService.userName;
    this.clientTxId = this.displayService.clientTransactionId;
    this.lastTransaction = this.displayService.lastTransaction;
  }

  public onClose() {
    this.dialogRef.close();
  }

  onCopy() {
    let copyData = {};
    copyData["timestamp"] = this.lastTransaction;
    copyData["clientTxId"] = this.clientTxId;
    if(this.userName) {
      copyData["userName"] = this.userName;
    }
    let copyTxt = JSON.stringify(copyData);
    this.clipboard.copy(copyTxt);

    // Disable the copy button for 1 second
    this.btnClipboardDisabled = true;
    timer(1000).subscribe(() => {
      this.btnClipboardDisabled = false;
    });
  }
}
