import {Subject} from "rxjs";
import {delay} from "rxjs/operators";

var redirected = false;
// Use asynchronous redirection, to allow original page to display all its messages
var redirect: Subject<string> = new Subject<string>();

// To give some time to the error message, introduce a delay of 3 seconds
redirect.pipe(delay(3000) ).subscribe( url => {
  // To ovoid multiple redirection requests, execute it only once.
  if(!redirected) {
    window.location.href = url;
    redirected = true;
  }
});

/**x`
 * Creates error message, returned from an API call.
 * @param message A message to prefix to the response from the API call
 * @param error The error, returned by the HttpClient
 * @return The error message to display
 */
export function errorMessageHttp(err: any): string {
    console.error(`${JSON.stringify(err)}`);
    if(err?.error) {
      let errorCode = err.error?.errorCode;
      if (errorCode) {
        switch (errorCode) {
          case "8800004":
            return "User not found";
          case "8800015":
            return "The user is disabled";
        }
        // The authentication hub returns the error in the element errorMessage
        if (err.error?.errorMessage)
          return err.error.errorMessage;
      }
    }
    return `Unknown authentication error`;
}
