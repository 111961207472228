import { Component } from '@angular/core';
import { faUserShield } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-logout-page',
  templateUrl: './logout-page.component.html'
})
export class LogoutPageComponent {
  leaveIcon = faUserShield;
}
