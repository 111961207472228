import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RememberInfoService {

  private currentFactorLevel: string;
  rememberFactors: boolean;

  constructor() { }

  /*
    Use the display service to obfuscate the details
    of the implementation
  */
  setUsername(userName) {
    localStorage.setItem("rememberMe", userName);
  }

  getUsername() {
    return localStorage.getItem("rememberMe");
  }

  clearRememberMe() {
    localStorage.removeItem("rememberMe");
  }

  clearLastFactors() {
    localStorage.removeItem("rememberFactors");
  }

  setCurrFactor(factor: any) {
    this.setNthFactor(this.currentFactorLevel, factor);
  }

  getCurrFactor(): string {
    return this.getNthFactor(this.currentFactorLevel);
  }

  getNthFactor(level: string): string {
    let factorsList = JSON.parse(localStorage.getItem("rememberFactors")) || {};
    return factorsList[level];
  }

  setNthFactor(level: string, factor: string) {
    let factorsList = JSON.parse(localStorage.getItem("rememberFactors")) || {};
    factorsList[level] = factor;
    localStorage.setItem("rememberFactors", JSON.stringify(factorsList));
  }

  reset() {
    this.clearRememberMe();
    this.clearLastFactors();
    this.clearLastFactors();
  }

  setLevel(level: string) {
    this.currentFactorLevel = level;
  }

  clearCurrentFactor() {
    this.setCurrFactor(null);
  }
}
