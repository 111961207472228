import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DisplayService } from './display.service';
import {filter, Observable, of, repeat, take, timer} from "rxjs";
import {switchMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PushService {

  readonly DELAY_STATUS_INTERVAL = 3000; // Time interval in milliseconds

  constructor(private http: HttpClient,
              private displayService: DisplayService
  ) { }

  public onGenerate(credId) {
    const postData = {
      credId: credId,
      action: "login",
      language: this.displayService.getBrowserLang(),
      deviceTag: true
    };
    return this.http.post(this.displayService.serviceUrl + "factor/v1/PushGenerator", postData);
  }

  public verifyPush(credId) {
    const postData = {
      credId: credId,
      deviceTag: true
    };
    return this.http.post(this.displayService.proxyUrl + 'factor/v1/PushStatus', postData);
  }

  public registerVipPush(credType, credValue, vipCredentialID) {
    const postData = {
      vipCredentialID: vipCredentialID,
      credType: credType,
      credValue: credValue,
      language: this.displayService.getBrowserLang(),
      deviceTag: true,
      userAccessToken: true
    };
    return this.http.post(this.displayService.serviceUrl + "factor/v1/VIPCredentialRegistrar", postData);
  }

  getVerifyObservable(credId): Observable<Object> {
    /* Base the PushStatus handler structure on empty observable.
     * The actual Push Status call must be an internal observable,
     * or else only partial data is propagated.
     */
    return of({}).pipe(
      // Make a new Push Status call
      switchMap(() => this.verifyPush(credId)),
      // Add a timer on all follow-up calls
      repeat({delay: (index) => {
          return (index === 0) ? null : timer(this.DELAY_STATUS_INTERVAL)
        }}),
      // Filter results for end state
      filter((results) => {
        // Check full and partial data for status, ending if not IN_PROGRESS or on error
        if (results?.['data']?.['status'] == null && results?.['status'] !== "IN_PROGRESS") {
          return true;
        } if (results?.['status'] == null && results?.['data']?.["status"] !== "IN_PROGRESS") {
          return true;
        } else if (results?.["errorCode"]) {
          return true;
        } else {
          return false;
        }
      }),
      // Keep trying until success. Failure will bypass this
      take(1)
    );

  }
}
