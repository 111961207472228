import {Injectable} from "@angular/core";
import {DisplayService} from "./display.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private displayService: DisplayService,
    private http: HttpClient
  ) {
  }

  /**
   * Access token call for direct login to access self-service screens
   */
  public getClientAccessToken(): Observable<Object> {
    return this.http.get('v1/token/generate/client_token');
  }

  public getUserAccessToken(idToken) {
    const postData = {
      assertion: idToken
    };
    return this.http.post('v1/token/generate/user_token', postData);
  }

  public refreshAccessToken(refreshToken: string) {
    const postData = {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      scope: "openid email profile offline_access"
    };
    return this.http.post('v1/token/generate/refresh', postData);
  }

  /**
   * Broadcom's implementation adheres to IETF RFC 7009:
   * - Revoking the RT will revoke both the AT and RT from the same grant.
   * - Revoking the AT will revoke AT but not necessarily the RT.
   * @param token
   */
  public revokeTokens(token: string) {
    const postData = {
      token: token
    };
    return this.http.post('v1/token/revoke', postData);
  }

}
