<div class="position-relative">
  <div>
    <input [formControl]="control" [placeholder]="name" [type]="hide ? 'password' : 'text'"
           class="form-control user-input"
           [ngClass]="{'input-error': control?.dirty && (control?.errors || invalidCondition)}"/>
  </div>
  <div class="toggleSightContainer d-flex flex-row-reverse p-2" (mousedown)="showText()" (mouseup)="hideText()" (mouseleave)="hideText()">
    <div class="my-auto align-middle">
      <fa-icon *ngIf="hide" [icon]="hiddenIcon" title="Hidden Icon"></fa-icon>
      <fa-icon *ngIf="!hide" [icon]="visibleIcon" title="Visible Icon"></fa-icon>
    </div>
  </div>
</div>
