import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DisplayService} from "./display.service";

@Injectable({
  providedIn: 'root'
})
export class IdpService {

  idpFriendlyName: string;

  constructor(private http: HttpClient,
              private displayService: DisplayService) {
  }

  generateIdpSaml() {
    return this.http.get(this.displayService.serviceUrl + "oauth2/v1/idp", {responseType: 'text'});
  }
}
