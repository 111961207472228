import {Component, OnInit} from '@angular/core';
import {DisplayService} from '../service/display.service';
import {WelcomeService} from "../service/welcome.service";
import {Router} from "@angular/router";
import {AuthStateService} from "../service/auth-state.service";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html'
})
export class WelcomeComponent implements OnInit {
  userName = null;
  errorDiv = false;
  errorMsg: string;

  constructor(
    public displayService: DisplayService,
    private router: Router,
    private welcomeService: WelcomeService,
    private authStateService: AuthStateService
  ) {
  }

  ngOnInit() {
    if (this.authStateService.userAccessToken == null) {
      this.router.navigate(["../"]);
    }
    this.userName = this.displayService.userName;
    document.querySelector("body").classList.add("authenticatedUser");

    if (this.displayService.loginName == null) {
      this.callUserInfo()
    } else {
      this.userName = this.displayService.loginName;
    }
  }

  private callUserInfo() {
    this.welcomeService.getUserInfo(this.authStateService.userAccessToken).subscribe({
      next: (response) => {
        let claims = null;
        if(response.headers.get('Content-Type') === 'application/json'){
          claims = window['KJUR'].jws.JWS.readSafeJSONString(response.body);
        }
        else{
          claims = this.decodeToken(response)[1];
        }
        this.displayService.uatFailed = false;
        if (claims['name'])  {
          this.userName = claims['name'];
          this.displayService.loginName = this.userName;
        } else {
          this.displayService.loginName = this.userName;
        }
      },
      error: () => {
        this.displayService.uatFailed = true;
      }
    });
  }

  decodeToken(response: any) {
    if (!response.body) {
      return [];
    }

    const sJWT = response.body.split('.');
    const uHeader = window['b64utos'](sJWT[0]);
    const uClaim = window['b64utos'](sJWT[1]);
    const pHeader = window['KJUR'].jws.JWS.readSafeJSONString(uHeader);
    const pClaim = window['KJUR'].jws.JWS.readSafeJSONString(uClaim);
    return [pHeader, pClaim];

  }

}
