<div class="visible" *ngIf="errorDiv">
  <div class="row">
    <div class="alert-red col">
      {{errorMsg ? errorMsg : "Unable to handle the request. Please try again later."}}&nbsp;
    </div>
    <div class="col-3 align-right" *ngIf="enableMoreInfo">
      <a (click)="troubleshoot()" class="reference" >More Info</a>
    </div>
  </div>
</div>
