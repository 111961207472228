import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { DisplayService } from './display.service';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(
    private http: HttpClient,
    private displayService: DisplayService,
  ) { }

  public onPasswordChange(currentPassword,newPassword) {
    const postData = {
      currentPassword,
      newPassword
    };
    return this.http.put(this.displayService.proxyUrl + 'factor/v1/MePasswordUpdater', postData);
  }
}


