import {Component} from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
/**
 * This is the default landing page component which displays basic information about the product and will route
 * the user to begin authentication via click
 */
export class HomeComponent {

}
