import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public allowUserReset: boolean = true;
  credValue = '';
  credType = '';
  userName = '';
  rememberDevice = false;

  constructor() { }

  /**
   * method To get userName
   */
  getUserName() {
    return this.userName;
  }

  /**
   * method To set userName
   * @params userName:any
   */
  setUserName(userName: string) {
    if (this.isUrl(userName)) {
      this.userName = null;
    } else {
      this.userName = userName;
    }
  }

  private isUrl(userName: string) {
    try {
      return Boolean(new URL(userName));
    } catch {
      return false;
    }
  }

  setRememberDeviceEnabled(rememberDevice: boolean) {
    this.rememberDevice = rememberDevice;
  }

  isRememberDeviceEnabled() {
    return this.rememberDevice;
  }
  enableUserReset() {
    this.allowUserReset = true;
  }

  disableUserReset() {
    this.allowUserReset = false;
  }

}
