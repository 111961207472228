import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {GlobalService} from './global.service';
import {AuthStateService} from "./auth-state.service";
import {RememberInfoService} from "./rememberInfo.service";

@Injectable({
  providedIn: 'root'
})
export class DisplayService {

  registrationEnabled: boolean = false;
  passwordEnabled: boolean = false;
  userNameEnabled: boolean = false;
  registerOtp: boolean = false;
  registerPush: boolean = false;
  pushOptions: string[] = [];
  userName: string;
  flowState: string;
  action: string;
  errorMsg: string;
  errorDiv: boolean = false;
  successMessage: boolean = false
  selectPush: boolean = false;
  factorOptions: string[] = [];
  customFactorOptions: Object = {};
  fidoFactors: boolean = false;
  factorSelection: boolean = false;
  registerMobileOtp: boolean = false;
  otpOptions: string[] = [];
  serviceUrl: string;
  proxyUrl: string;
  flowStateId: string;
  baseHost: string;
  inlineMessage: string;
  reauthMessage: string;
  clientTransactionId: any
  reauthObservable: Subject<any>;
  loginName: string;
  fidoCredType;
  preferredFactor;
  private fidoComponentResetFunction: Function;
  private nextActionTrace;
  uatFailed: boolean = false;
  lastTransaction: Date;
  private flowStateSubject = {};

  constructor(private router: Router,
              private globalService: GlobalService,
              private rememberService: RememberInfoService,
              private authService: AuthStateService) {
    this.reauthObservable = new Subject();
  }

  processFactorOptions(factorOptions) {
    if (!factorOptions) {
      return;
    }
    let newArray = factorOptions;
    if (factorOptions && factorOptions.indexOf("FIDO") > -1 && factorOptions.indexOf("SECURITYKEY") > -1) {
      /**
       * Ensuring that Biometric & SecurityKey options are displayed together in the UI.
       */
      newArray = [];
      factorOptions.forEach((factor) => {
        if (factor !== "FIDO" && factor !== "SECURITYKEY") {
          newArray.push(factor);
        } else if (factor === "FIDO") {
          newArray.push("FIDO");
          newArray.push("SECURITYKEY");
        }
      });
      factorOptions = newArray;
    }
    if (factorOptions && factorOptions.indexOf("SMSOTP") > -1 && factorOptions.indexOf("EMAILOTP") > -1) {
      /**
       * Ensuring that SMSOTP & EMAILOTP options are displayed together in the UI.
       */
      newArray = [];
      factorOptions.forEach((factor) => {
        if (factor !== "SMSOTP" && factor !== "EMAILOTP") {
          newArray.push(factor);
        } else if (factor === "SMSOTP") {
          newArray.push("SMSOTP");
          newArray.push("EMAILOTP");
        }
      });
      factorOptions = newArray;
    }
    if (factorOptions && factorOptions.indexOf("MOBILEOTP") > -1 && factorOptions.indexOf("PUSH") > -1) {
      /**
       * Ensuring that MOBILEOTP & PUSH options are displayed together in the UI.
       */
      newArray = [];
      factorOptions.forEach((factor) => {
        if (factor !== "MOBILEOTP" && factor !== "PUSH") {
          newArray.push(factor);
        } else if (factor === "MOBILEOTP") {
          newArray.push("MOBILEOTP");
          newArray.push("PUSH");
        }
      });
      factorOptions = newArray;
    }
    return newArray || factorOptions;
  }

  processCustomFactorOptions(predicates) {
    if (!predicates) {
      return;
    }

    let result: Object = {};
    predicates.forEach((factorDef)=> {
      let factor:string = factorDef["factor"];
      if(factor.startsWith("CUSTOM/")) {
        result[factor] = factorDef["providerFlowId"]
      } else if (factor.startsWith("PASSWORD/")) {
        result[factor] = factorDef["predicate"]
      } else if (factor.startsWith("IDP/")) {
        result[factor] = factorDef["predicate"]
      }
    });
    return result;
  }

  routeActions(data): Promise<boolean | void> {
    this.updateCurrentFactorLevel(data);
    let nextRoute: string;
    if (data === null) {
      this.errorDiv = true;
      this.errorMsg = "Server error, response is null"
      nextRoute = '**';
    } else {
      if (data["currentFactors"] && data["currentFactors"].length > 1) {
        this.factorSelection = true;
      } else {
        this.factorSelection = false;
      }
      this.action = data["nextaction"];
      this.flowState = data["flowState"];
      if (data["userName"]) {
        this.userName = data["userName"];
        this.globalService.setUserName(this.userName);
      }

      if (data["nextaction"] === "PASSWORD_AUTH") {
        this.userNameEnabled = false;
        this.passwordEnabled = true;
        this.inlineMessage = data["inline_registration"];
        this.authService.setAuthStatusFactor();
        nextRoute = 'verify';
      } else if (data["nextaction"] === "FIDO_AUTH_GENERATE_CHALLENGE") {
        this.fidoCredType = "FIDO";
        this.registrationEnabled = false;
        this.authService.setAuthStatusFactor();
        nextRoute = 'fido';
      }
      else if (data["nextaction"] === "FIDO_REGISTER_GENERATE_CHALLENGE") {
        this.fidoCredType = "FIDO";
        this.registrationEnabled = true;
        this.authService.setAuthStatusFactor();
        nextRoute = 'fido';
      } else if (data["nextaction"] === "SECURITYKEY_AUTH_GENERATE_CHALLENGE") {
        this.fidoCredType = "SECURITYKEY";
        this.registrationEnabled = false;
        this.authService.setAuthStatusFactor();
        nextRoute = 'security-key';
      }
      else if (data["nextaction"] === "SECURITYKEY_REGISTER_GENERATE_CHALLENGE") {
        this.fidoCredType = "SECURITYKEY";
        this.registrationEnabled = true;
        this.authService.setAuthStatusFactor();
        nextRoute = 'security-key';
      }
      else if (data["nextaction"] === "MOBILEOTP_REGISTER") {
        this.registerMobileOtp = true;
        this.authService.setAuthStatusFactor();
        nextRoute = 'motp';
      }
      else if (data["nextaction"] === "MOBILEOTP_AUTH") {
        this.registerMobileOtp = false;
        this.otpOptions = data['credentials'];
        this.flowState = data["flowState"];
        this.authService.setAuthStatusFactor();
        nextRoute = 'motp';
      }
      else if (data["nextaction"] === "PUSH_REGISTRATION_INITIATOR") {
        this.flowState = data["flowState"];
        this.authService.setAuthStatusFactor();
        nextRoute = 'push';
      }
      else if (data["nextaction"] === "PUSH_REGISTRATION_STATUS") {
        this.registerPush = true;
        this.flowStateId = data["flowStateId"];
        this.baseHost = data["baseHost"];
        this.authService.setAuthStatusFactor();
        nextRoute = 'push';
      }
      else if (data["nextaction"] === "PUSH_SELECTION") {
        this.pushOptions = data["credentials"];
        this.selectPush = true;
        this.authService.setAuthStatusFactor();
        nextRoute = 'push';
      }
      else if (data["nextaction"] === "FACTOR_SELECTION") {
        this.preferredFactor = undefined;
        this.factorSelection = true;
        this.factorOptions = this.processFactorOptions(data["currentFactors"]);
        data["currentFactors"] = this.factorOptions;
        this.customFactorOptions = this.processCustomFactorOptions(data["additional"]["predicatesForCurrentFactors"]);
        this.preferredFactor = null;
        this.authService.setAuthStatusFactor();
        nextRoute = 'factorselection';
      }
      else if (data["nextaction"] === "SMS_OTP_REGISTER") {
        this.registerOtp = true;
        this.authService.setAuthStatusFactor();
        nextRoute = 'sotp';
      }
      else if (data["nextaction"] === "SMS_OTP_SELECTION") {
        this.registerOtp = false;
        this.otpOptions = data['credentials'];
        this.authService.setAuthStatusFactor();
        nextRoute = 'sotp';
      }
      else if (data["nextaction"] === "SMS_OTP_AUTH") {
        this.registerOtp = false;
        this.flowState = data["flowState"];
      }
      else if (data["nextaction"] === "EMAIL_OTP_REGISTER") {
        this.registerOtp = true;
        this.authService.setAuthStatusFactor();
        nextRoute = 'eotp';
      }
      else if (data["nextaction"] === "EMAIL_OTP_SELECTION") {
        this.registerOtp = false;
        this.otpOptions = data['credentials'];
        this.authService.setAuthStatusFactor();
        nextRoute = 'eotp';
      }
      else if (data["nextaction"] === "EMAIL_OTP_AUTH") {
        this.registerOtp = false;
        this.flowState = data["flowState"];
      }
      else if (data["nextaction"] === "IVR_OTP_REGISTER") {
        this.registerOtp = true;
        this.authService.setAuthStatusFactor();
        nextRoute = 'votp';
      }
      else if (data["nextaction"] === "IVR_OTP_SELECTION") {
        this.registerOtp = false;
        this.otpOptions = data['credentials'];
        this.authService.setAuthStatusFactor();
        nextRoute = 'votp';
      }
      else if (data["nextaction"] === "IVR_OTP_AUTH") {
        this.registerOtp = false;
        this.flowState = data["flowState"];
      }
      else if (data["nextaction"] == "CHANGE_PASSWORD") {
        this.registerOtp = false;
        this.router.navigate(['changePassword'])
      }
      else if (data["nextaction"] === "CUSTOM_AUTH") {
        let flowId: string = this.getCustomFactorId(data);
        if (flowId === "securid") {
          this.authService.setAuthStatusFactor();
          nextRoute = 'securid';
        } else {
          this.authService.setAuthStatusNone();
          nextRoute = 'denied';
        }
      }
      else if (data["nextaction"] === "IDP_AUTH") {
        this.authService.setAuthStatusFactor();
        nextRoute = 'idp';
      }
      else if (data["nextaction"] === "AUTH_DENIED") {
        this.authService.setAuthStatusNone();
        nextRoute = 'denied';
      }
      else if (data["nextaction"] === "AUTH_ALLOWED") {
        this.userNameEnabled = false;
        this.passwordEnabled = false;
        this.successMessage = true;
        if (data?.data?.message) {
          this.reauthMessage = data["data"]["message"];
          this.reauthObservable.next(this.reauthMessage);
        }
        if (data['authCompleteUrl'])
          this.doAuthRedirect(data['authCompleteUrl']);
        else {
          if (data.additional && data.additional.trustedDeviceId) {
            let days = +data.additional.trustedDeviceExpiryDays || 0;
            let expires = new Date();
            expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
          }
          this.flowState = null;
          this.loginName = null;
          this.authService.parseUatResponse(data);
          nextRoute = 'welcome';
        }
      }
    }
    if (data.nextaction) {
      this.nextActionTrace = this.nextActionTrace || {};
      this.nextActionTrace["previous"] = this.nextActionTrace["current"];
      this.nextActionTrace["current"] = data.nextaction;
    }

    if (nextRoute) {
      // Wrapping navigations like this fixes the issue when navigating to same page, but not triggering lifecycle hooks.
      // This will cause angular to destroy and reinitialize the component always.
      return this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([nextRoute]);
      });
    } else {
      return new Promise<boolean>((resolve) => { resolve(false); });
    }
  }

  /**
   * A function for obfuscating strings.
   *
   * IMPORTANT NOTE:
   * This obfuscation logic should NOT be changed, as the same logic resides at the Java side also.
   * AuthHub-Commons/core-utils/src/main/java/com/broadcom/layer7authentication/core/utils/StringObfuscator.java
   *
   * @param str - The string to be obfuscated
   * @returns obfuscated string
   */
  obfuscate(str) {
    if (!str) {
      throw new Error("Invalid string input specified.");
    }
    let _str = btoa(encodeURIComponent(str.toLowerCase()));
    _str = _str.replace(/\//g, "").replace(/\+/g, "").replace(/\=/g, "");
    let _sum = 0;
    for (let i = 0; i < _str.length; i += 4) {
      _sum += parseInt(_str.substr(i, 4), 36);
    }
    return _sum.toString(36);
  }

  focusNthField(index: number = 0, timeout: number = 100) {
    setTimeout(() => {
      let nthField;
      let elements = document.querySelectorAll("input[type='text']:not([readonly]),input[type='password']:not([readonly])");
      nthField = elements[index];
      nthField && nthField.focus();
    }, timeout);
  }

  getBrowserLang() {
    let fallback = "en_US";
    if (typeof window === "undefined" || typeof window.navigator === "undefined") {
      return fallback;
    }
    let browserLang = window.navigator.languages ? window.navigator.languages[0] : null;
    browserLang = browserLang || window.navigator.language || window.navigator["browserLanguage"] || window.navigator["userLanguage"];
    if (typeof browserLang === "undefined") {
      return fallback;
    }
    if (browserLang.indexOf("-") !== -1) {
      browserLang = browserLang.split("-").join("_");
    }
    if (!browserLang || browserLang.length < 5 || browserLang.indexOf("_") === -1) {
      return fallback;
    }
    let browserLangParts = browserLang.split("_");
    browserLangParts[1] = (browserLangParts[1] || "").toUpperCase();
    browserLang = browserLangParts.join("_");
    return browserLang;
  }

  updateCurrentFactorLevel(data) {
    if (data['additional'] && data['additional']['currentFactorLevel']) {
      this.rememberService.setLevel(data?.['additional']?.['currentFactorLevel']);
    }
  }


  /**
   * method To set flowstate
   * @params data: flowstate
   */
  setFlowState(key, data) {
    this.flowStateSubject[key] = data;
  }

  /**
   * handle api response data
   * @params data:any;
   * @params nexAction:string;
   */
  processResponse(data) {
    this.setSubSequentApisData(data);
  }

  /**
   * method To set Api response data
   */
  setSubSequentApisData(response) {
    if (response) {
      if (response.additional) {
        if (response.additional.isTrustedDeviceEnabled === true) {
          this.globalService.setRememberDeviceEnabled(true);
        }
      }
      response.flowState && this.setFlowState(response.nextaction, response.flowState);
      if (response.flowState) {
        this.flowState = response.flowState;
      }
    }
  }

  /**
   * TODO: Remove this method once Broadcom provides fix. Should be addressed in 3.2 release. We
   *       should always use customFlowId, but it is missing when custom authenticator is the only factor in
   *       secondary authentication stage. For more info, see Broadcom case 33682869 or Jira SECDEV-3025.
   * @param data Response body object
   * @
   */
  getCustomFactorId(data): string {
    let flowId: string;
    if (data?.additional?.customFlowId) {
      // NORMAL CASE: Use customFlowId
      flowId = data?.additional?.customFlowId;
    } else if (data?.additional?.predicatesForCurrentFactors?.length==1) {
      // TEMPORARY FIX: Use providerFlowId
      flowId = data?.additional?.predicatesForCurrentFactors[0]?.providerFlowId;
    }
    return flowId;
  }

  isRememberedFactorAvailable() {
    let currSavedFactor = this.rememberService.getCurrFactor();
    return this.factorIsPresent(currSavedFactor);
  }

  private factorIsPresent(factorSelection: string) {
    // Validate factor is in current list of factor options
    return (this.factorOptions.find(factor => factor === factorSelection));
  }

  private doAuthRedirect(redirectUrl) {
    redirectUrl = new URL(redirectUrl);
    let baseServiceUrl = new URL(this.serviceUrl).origin;
    let safeRedirectUri = baseServiceUrl + "/common/oauth2/v1/authcomplete" + redirectUrl.search;
    location.href =  safeRedirectUri;
  }

  /**
   * Method disables the browser's back button capabilities. Best practice is to avoid affecting the
   * browser's behavior. However, it can be justified in certain use cases. We need this capability because
   * AuthHub uses a complex multistep authentication flow across various Angular components.
   *
   * Invoke this method on flow-dependent pages (i.e. factor pages), so users cannot unintentionally disrupt
   * the authentication flow.
   */
  disableBrowserBackButton() {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  }
}
