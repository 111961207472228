import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DisplayService} from "./display.service";

/**
 * Interacts with YMA for RSA AA and SecruID authentication
 */
@Injectable({
  providedIn: 'root'
})
export class YmaService {

  constructor(
    private http: HttpClient,
    private displayService: DisplayService
  ) { }

  public onSecurIDLogin(code) {

    const operationalContext = [];
    operationalContext.push({key: "spi.auth.factor.flowid", value: "securid"})

    const credData = [];
    credData.push({key: "code", value: code})

    const postData = {
      operationalContext: operationalContext,
      credData: credData
    };
    return this.http.post(this.displayService.proxyUrl + 'factor/v1/CustomAuthenticator', postData);
  }

}
