import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { DisplayService } from './display.service';

@Injectable({
  providedIn: 'root'
})
export class LdapService {

  constructor(
    private http: HttpClient,
    private displayService: DisplayService
  ) { }

  public onPasswordLogin(password) {
    const postData = {
      password,
      deviceTag: true
    };
    return this.http.post(this.displayService.proxyUrl + 'factor/v1/PasswordAuthenticator', postData);
  }
}


