import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { GlobalService } from '../service/global.service';
import { InitService } from '../service/init.service';
import {ResetService} from "../service/reset.service";
import {RememberInfoService} from "../service/rememberInfo.service";
import {AuthStateService} from "../service/auth-state.service";

@Component({
  selector: 'app-userinfo',
  templateUrl: './userinfo.component.html',
  styleUrls: ['./userinfo.component.scss']
})
export class UserinfoComponent implements OnInit {

  userName: string;
  isAuthenticated: boolean;
  @Input() title :string;
  @Output() resetEmitter : EventEmitter<any> = new EventEmitter();

  constructor(public globalService: GlobalService,
              private initService: InitService,
              private rememberService: RememberInfoService,
              private authStateService: AuthStateService,
              private resetService: ResetService) { }

  ngOnInit(): void {
    this.userName = this.globalService.getUserName();
    this.authStateService.getAuthStatusSource().subscribe({
      next: (state) => {
        this.isAuthenticated = this.authStateService.isAuthenticated()
      }
    })
  }

  notUser() {
    if (this.globalService.allowUserReset) {
      this.rememberService.reset();
      this.resetService.resetAuthFlow();
      this.initService.loginEnabled = true;
      this.resetEmitter.emit();
    }
  }
}
