import {Component, OnInit} from '@angular/core';
import {RememberInfoService} from "../service/rememberInfo.service";
import {DisplayService} from "../service/display.service";
import {GlobalService} from "../service/global.service";

@Component({
  selector: 'app-denied',
  templateUrl: './denied.component.html'
})
export class DeniedComponent implements OnInit {

  constructor(private rememberInfoService: RememberInfoService,
              private globalService: GlobalService) {}

  ngOnInit(): void {
    this.clearRememberDenied();
  }

  private clearRememberDenied() {
    // Clear stored user
    this.rememberInfoService.clearRememberMe();
    this.globalService.userName = null;
  }
}
