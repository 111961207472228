import {Component, OnDestroy, OnInit} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DisplayService } from "../service/display.service";
import { GlobalService } from '../service/global.service';
import { InitService } from "../service/init.service";
import {TokenService} from "../service/token.service";
import {errorMessageHttp} from "../shared/error-messages";
import {Subscription} from "rxjs";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {AuthStateService} from "../service/auth-state.service";
import {ResetService} from "../service/reset.service";
import {RememberInfoService} from "../service/rememberInfo.service";


@Component({
  selector: "app-init",
  templateUrl: "./init.component.html",
  styleUrls: ["./init.component.scss"],
})
export class InitComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  userName: string;
  errorDiv = false;
  errorMsg: string;
  rememberMe: boolean = false;
  ready: boolean = false;
  initialSubmit: boolean = true;
  pageSubs: Subscription[] = new Array<Subscription>();
  warningIcon = faExclamationTriangle;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public initService: InitService,
    private displayService: DisplayService,
    private rememberService: RememberInfoService,
    private globalService: GlobalService,
    private tokenService: TokenService,
    private authStateService: AuthStateService,
    private resetService: ResetService
  ) {
  }

  ngOnInit() {
    this.ready = false;
    this.errorMsg = this.initService.errorMsg;
    this.loginForm = this.formBuilder.group({
      userName: ["", [Validators.required, Validators.minLength(1)]],
      rememberMe: [false]
    });
    this.authStateService.setAuthStatusNone();
    this.initialSubmit = true;
    this.globalService.enableUserReset();
    this.displayService.focusNthField();

    this.initAppLogin();
  }

  /**
   * Prepare members and set values for App access token current call
   * @private
   */
  private initAppLogin() {
    // This block is just grabbing env and saving in display component
    this.errorDiv = false;
    this.displayService.clientTransactionId = this.resetService.getClientTxId();
    this.displayService.flowState = null;
    console.log("Initialize Tokens");
    this.initializeAppToken();
  }

  /**
   * Get bearer access token
   * @private
   */
  private initializeAppToken() {
    this.pageSubs.push(this.tokenService.getClientAccessToken().subscribe({
      next: (data: Object) => {
        this.errorDiv = false;
        this.authStateService.clientAccessToken = data['access_token'];
        this.initiateFlow();
      },
      error: (err) => {
        this.ready = true;
        this.errorDiv = true;
        if (err.error && err.error.error_description) {
          this.errorMsg = err.error.error_description;
        } else {
          this.errorMsg = "Unexpected error, please try again.";
        }
      }
    })
    );
  }

  /**
   * This function will determine the correct login flow: direct for self-service(direct) or an auth code grant(oauth2)
   */
  private initiateFlow(){
    // Determine login state
    if (window.location.search === "?restart=true" && sessionStorage.getItem("x-flow")==="oauth2") {
      // An oauth2 flow has been restarted. Restore it.
      this.restoreOauth2Flow();
      this.oauth2Login();
    } else if(window.location.pathname.toString().endsWith("/oauth2")) {
      // Initial oauth2 flow
      sessionStorage.setItem("x-flow", "oauth2");
      this.resetService.setQueryParams();
      this.oauth2Login();
    } else {
      // Direct login
      sessionStorage.setItem("x-flow", "direct");
      this.displayService.flowState = null;
      this.resetService.clearQueryParams();
      this.tryRememberMe();
    }
  }

  private restoreOauth2Flow() {
    console.log("Restoring oauth2 flow");
    this.restoreQueryParams();
    if (this.isNewUserFlowNeeded()) {
      // If we had initial flow state passed, disassociate the user and get new flow state
      this.getNewUserFlowState();
    } else {
      this.setupOauth2Flow();
    }
  }

  /**
   * Retrieves access token for authorization Code Grant
   * @private
   */
  private setupOauth2Flow() {
    this.pageSubs.push(this.tokenService.getClientAccessToken().subscribe({
        next: (data) => {
          this.errorDiv = false;
          this.authStateService.clientAccessToken = data['access_token'];
          this.initService.settingsMsg = false;
          this.initService.errorMsg = '';
        },
        error: (err) => {
          this.ready = true;
          this.errorDiv = true;
          this.initService.settingsMsg = true;
          if (err.error && err.error.error_description) {
            this.initService.errorMsg = err.error.error_description;
            this.errorMsg = this.initService.errorMsg;
          } else {
            this.initService.errorMsg = 'unable to reach  ' + this.displayService.serviceUrl;
            this.errorMsg = "Unexpected error, please try again.";
          }
        }
      })
    );
  }

  onLogin() {
    this.errorDiv = false;
    this.ready = false;
    this.userName = this.loginForm.value.userName;
    this.rememberMe = this.loginForm.value.rememberMe;

    if (this.userName) {
      this.setUsername(this.userName);
    }

    this.pageSubs.push(this.initService.authenticate(this.userName, this.rememberMe).subscribe({
        next: (data) => {
          this.errorDiv = false;
          this.errorMsg = "";
          this.displayService.userName = data['username'];
          this.displayService.routeActions(data);
        },
        error: (err) => {
          this.errorDiv = true;
          this.errorMsg = errorMessageHttp(err);
          this.ready = true;
          this.displayService.focusNthField();
        }
      })
    );
  }

  /**
   * Attempt to find existing username to use. Try via id propagation, wait, then try via remember me.
   * @private
   */
  private tryRememberMe() {
    if (this.isRememberMe()) {
      // When username is stored in RememberMe
      this.setUsername(this.rememberService.getUsername());
      this.onLogin();
    } else {
      this.ready = true;
      this.displayService.focusNthField();
    }
  }

  isRememberMe() {
    if (window.location.href.indexOf("restart=true") === -1 && this.rememberService.getUsername()) {
      return true;
    } else {
      return false;
    }
  }

  private setUsername(userName: string) {
    if (this.rememberMe) {
      this.rememberService.setUsername(this.userName);
    }
    this.userName = userName;
    this.loginForm.value.userName = this.userName;
    this.globalService.setUserName(this.userName);
    this.displayService.userName = this.userName;
    this.initialSubmit = false;
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub) => {
      sub?.unsubscribe();
    });
  }

  private restoreQueryParams() {
    if (this.resetService.queryParams.get('x-flow-state')) {
      this.displayService.flowState = this.resetService.queryParams.get("x-flow-state");
    }
    if (!this.displayService.clientTransactionId) {
      this.displayService.clientTransactionId = this.resetService.getClientTxId();
    }
  }

  private isNewUserFlowNeeded() {
    if (this.resetService.queryParams.get("x-flow-state")) {
      return true;
    } else {
      return false;
    }
  }

  private getNewUserFlowState() {
    this.resetService.resetUser().subscribe({
      next: (data) => {
        // Override existing x-flow-state dissociated from previous user
        if (data['flowState']) {
          this.displayService.flowState = data['flowState'];
        } else {
          this.displayService.errorMsg = "Error resetting user";
          this.displayService.errorDiv = true;
          this.authStateService.setAuthStatusRestartNeeded()
        }
        this.setupOauth2Flow();
      },
      error: (err) => {
        // If fail to reset user and x-flow-state, enter error state
        console.log(err);
        this.displayService.errorMsg = "Error resetting user";
        this.displayService.errorDiv = true;
        this.authStateService.setAuthStatusRestartNeeded()
      }
    });
  }

  private oauth2Login() {
    this.pageSubs.push(this.initService.authenticate(this.userName, this.rememberMe).subscribe({
      next: (data) => {
        this.errorDiv = false;
        this.errorMsg = "";
        // ID propagation - submit existing user if found
        if (data?.nextaction && data?.userName) {
          this.globalService.disableUserReset();
          this.displayService.routeActions(data);
        } else {
          // If no ID propagation, continue with other user recognition mechanism (remember me)
          this.tryRememberMe();
        }
      },
      error: (err) => {
        this.errorDiv = true;
        this.errorMsg = errorMessageHttp(err);
        this.ready = true;
        this.displayService.focusNthField();
      }
    })
    );
  }

  resetUser($event: any) {
    this.pageSubs.forEach((sub) => {
      sub.unsubscribe();
    });
    this.ready = true;
  }
}
