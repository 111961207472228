import {Component, OnInit} from '@angular/core';
import {DisplayService} from "../service/display.service";
import {ResetService} from "../service/reset.service";
import {AuthState, AuthStateService} from "../service/auth-state.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-need-restart',
  templateUrl: './need-restart.component.html'
})
export class NeedRestartComponent implements OnInit {
  errorMsg: any;
  errorDiv: any;
  isOauthFlow: boolean;

  constructor(private displayService: DisplayService,
              private resetService: ResetService,
              private authStateService: AuthStateService,
              private router: Router) { }

  ngOnInit(): void {
    if (this.authStateService.getAuthStatus() === AuthState.RESTART_NEEDED) {
      this.errorMsg = this.displayService.errorMsg;
      this.errorDiv = this.errorMsg ? true : false;
      this.isOauthFlow = (sessionStorage.getItem("x-flow") === "oauth2");
    } else {
      // If the page refreshed, redirect home
      this.router.navigate(['home']);
    }
  }

  restartAuthFlow() {
    this.resetService.resetAuthFlow();
  }

}
