import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-emea-cookies',
  templateUrl: './emea-cookies.component.html'
})
export class EmeaCookiesComponent implements OnInit {

  @HostListener('window:message', ['$event'])
  private handlePostMessage(msg: MessageEvent) {
    // Listen for mutation events from iframe
    if (msg.data == 'mutation') {
      // Resize iframe
      this.iframe.style.height = this.iframe.contentWindow.document.body.scrollHeight + 'px';
      this.loading=false;
    }
  }

  private iframe: HTMLIFrameElement;
  loading = true;

  ngOnInit() {
    // Get iFrame element
    this.iframe = document.getElementById('iframe-ot-notice') as HTMLIFrameElement;
  }
}
