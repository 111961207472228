import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  private getPathForCookie() {
    return "/";
  }

  setCookie(name, value, expires?: Date) {
    if (!name || !value) {
      return;
    }
    let path = this.getPathForCookie();
    let expiresStr = "";
    if (expires) {
      expiresStr = ";expires=" + expires.toUTCString();
    }
    if (window.location.protocol === "https:") {
      document.cookie = "__Secure-" + name + "=" + encodeURIComponent(value) + expiresStr + ";path=" + path + ";secure;samesite=lax;";
    } else {
      document.cookie = name + "=" + encodeURIComponent(value) + expiresStr + ";path=" + path + ";samesite=lax;";
    }
  }

  getCookie(name) {
    if (window.location.protocol === "https:") {
      name = "__Secure-" + name;
    }
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      if (cookies[i].indexOf(name + "=") === 0) {
        return decodeURIComponent(cookies[i].split("=").slice(1).join("="));
      }
    }
    return null;
  }

  getCookieInsecure(name) {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      if (cookies[i].indexOf(name + "=") === 0) {
        return decodeURIComponent(cookies[i].split("=").slice(1).join("="));
      }
    }
    return null;
  }

  removeCookie(name, options?: any) {
    let path = this.getPathForCookie();
    let dt = new Date();
    dt.setTime(dt.getTime() - 99999);
    let domainStr = "";
    if (options) {
      if (options.domain) {
        domainStr = ";domain=" + options.domain;
      }
    }
    if (window.location.protocol === "https:") {
      document.cookie = "__Secure-" + name + "=null;expires=" + dt.toUTCString() + domainStr + ";path=" + path + ";secure;samesite=lax;";
    } else {
      document.cookie = name + "=null;expires=" + dt.toUTCString() + domainStr + ";path=" + path + ";samesite=lax;";
    }
  }

}
