import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DisplayService } from './display.service';

@Injectable({
  providedIn: 'root'
})
export class WelcomeService {

  constructor(
    private http: HttpClient,
    private displayService: DisplayService
  ) { }

  public getUserInfo(userAccessToken) {
    const postData = { payloadTypeAttribute: 'access_token=' + userAccessToken, noToken: true };
    return this.http.post(this.displayService.serviceUrl + 'oauth2/v1/userinfo', postData, {responseType: 'text', observe: "response"});
  }
}

