import {Component, Input} from '@angular/core';
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.css']
})
export class PasswordInputComponent {

  // Placeholder name for empty field
  @Input() name: string;
  // FormControl object for the input
  @Input() control: FormControl;
  // Custom conditions for marking field as invalid
  @Input() invalidCondition: any;
  hide: boolean = true;
  hiddenIcon = faEyeSlash;
  visibleIcon = faEye;

  showText() {
    this.hide = false;
  }

  hideText() {
    this.hide = true;
  }

}
