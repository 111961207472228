import {Component, Input} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {TroubleshootComponent} from "../troubleshoot/troubleshoot.component";
import {DisplayService} from "../service/display.service";

@Component({
  selector: 'app-errormsg',
  templateUrl: './errormsg.component.html'
})
export class ErrormsgComponent {

  @Input() errorMsg: String;
  @Input() errorDiv: boolean = false;
  @Input() enableMoreInfo: boolean = true;

  constructor(private modalDialog: MatDialog,
              public displayService: DisplayService) {
  }

  troubleshoot() {
    this.modalDialog.open(TroubleshootComponent, {});
  }

}
